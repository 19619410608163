import {
  Inject,
  Injectable,
  PLATFORM_ID,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { CommonfunctionService } from './commonFunctionService';
import { BrowserService } from './browser.service';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { v4 as uuidv7 } from 'uuid';
import { isPlatformBrowser } from '@angular/common';
import { AlertService } from '../core/components/alerts/alert.service';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class ApiServiceService {
  private broadcastChannel: BroadcastChannel;
  public httpOptions: any;
  public status: any;
  public device: any;
  public current_city_slug = new BehaviorSubject<any>('');
  public is_user_loggedin = new BehaviorSubject<boolean>(
    this.browserService.getLocalValue('user_info') ? true : false
  );
  public user_info = new BehaviorSubject<any>(
    this.browserService.getLocalValue('user_info')
      ? JSON.parse(this.browserService.getLocalValue('user_info'))
      : null
  );
  public profile_image = new BehaviorSubject<any>(
    this.browserService.getLocalValue('profile_image')
      ? this.browserService.getLocalValue('profile_image')
      : null
  );
  public user_id: any;
  public user_name!: string;
  public user_token: string = '';

  public session_id: string = 'null';
  public language_id: any = '';
  public language_code: any = '';
  public language_list: any = [];
  public property_type_list = [];
  public entity_type_list = [];
  public allowResources = [];
  public umsUrl = '';
  public emUrl = '';
  public emBrokerUrl = '';
  public emValuationUrl = '';
  public wmsUrl = '';
  private API_KEY = '7kjghhjj';
  public city_code = '';
  public city_id: any = '13';
  public broker_id!: number;
  public isMobile: boolean;
  public city_list:any;
  public api_loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public lang: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.localizeRouterService.parser.currentLang
  );

  public cityData = new BehaviorSubject<any>('');
  public selected_city = new BehaviorSubject<any>(
    this.browserService.getLocalValue('selected_city')
      ? JSON.parse(this.browserService.getLocalValue('selected_city'))
      : null
  );
  public selected_language = new BehaviorSubject<any>(
    this.browserService.getLocalValue('selected_language')
      ? JSON.parse(this.browserService.getLocalValue('selected_language'))
      : null
  );
  public cities = new BehaviorSubject<any>(
    this.browserService.getLocalValue('cities')
      ? JSON.parse(this.browserService.getLocalValue('cities'))
      : null
  );

  ipblock: boolean = false;
  miUrl: any;
  miApp: string;
  em_ang: string;
  slug: any;
  currentUrlSSR: any;
  constructor(
    private http: HttpClient,
    private browserService: BrowserService,
    private fnc: CommonfunctionService,
    private route: Router,
    private deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: any,
    private alertService: AlertService,
    private authService: SocialAuthService,
    private localizeRouterService: LocalizeRouterService,
    private translate: TranslateService
   ) {

    if (isPlatformBrowser(this.platformId)) {
      this.broadcastChannel = new BroadcastChannel('auth');
      this.broadcastChannel.addEventListener('message', (event) => {
        if (event.data.action === 'logout') {
          // Perform logout actions
          this.logoutall();
        }
      });
      this.broadcastChannel = new BroadcastChannel('auth');
    }
    // if (isPlatformBrowser(this.platformId)) {
    //   this.broadcastChannel = new BroadcastChannel('auth');
    // }
    this.isMobile = this.deviceService.isMobile();
    this.selected_city.subscribe((data: any) => {
      if (data && data.place_id) {
        this.city_id = data.place_id;
        this.city_code = data.place_code;
        this.slug = data.slug;
      }
    });
    this.setApiUrl();
    this.setSession();
    if (
      this.browserService.getLocalValue('language_list') == undefined &&
      this.browserService.getLocalValue('language_list') == null
    ) {
      this.getLanguageList();
    } else {
      this.language_list = this.browserService.getLocalValue('language_list')
        ? JSON.parse(this.browserService.getLocalValue('language_list'))
        : null;
    }

    try {
      this.getCitis();
    } catch (error) { }

    this.selected_language.subscribe((lng: any) => {
      if (lng) {
        this.language_id = lng.language_id;
        this.language_code = lng.language_code;
      }
    });

    this.user_info.subscribe((user: any) => {
      if (user && user.user_id) {
        this.user_id = user.user_id;
        this.user_token = user.token;
        this.is_user_loggedin.next(true)
      }
    });

    this.lang.subscribe((re) => {
      if (re) {
        this.changeLanguage(re);
      }
    });

    if (this.user_id) {
      this.getUserDetail(this.user_id);
      this.getUserRole(this.user_id);
      this.getAllowResources();
    }
  }
  setSession() {
    if (this.browserService.getCookie('anonymous_session')) {
      this.session_id = this.browserService.getCookie('anonymous_session');
    } else {
      let session_id = uuidv7();
      if (session_id) {
        this.browserService.setCookie('anonymous_session', session_id, 1);
        this.session_id = session_id;
      }
    }
  }
  // set url of api
  setApiUrl() {
    this.umsUrl = environment.umsUrl;
    this.emUrl = environment.emUrl;
    this.emBrokerUrl = environment.EM_BROKER_API_URL;
    this.emValuationUrl = environment.EM_VALUATION_API_URL;
    this.user_token = (this.user_token == '' || this.user_token == undefined || this.user_token == null) ? environment.user_token : this.user_token;
    this.wmsUrl = environment.WMS_URL + this.city_code + '_gisdb/wms?';
    this.miUrl = environment.miUrl;
    this.miApp = environment.miApp;
    this.em_ang = environment.em_ang;
  }

  //GIS
  setGisHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('API-KEY', '7kjghhjj');
    headers = headers.append(
      'USER-LANGUAGE',
      this.language_id ? this.language_id.toString() : '1'
    );
    headers = headers.append('USER-CITY', this.city_code);
    headers = headers.append('USER-TOKEN', this.user_token);
    this.httpOptions = { headers: headers };
  }

  setEmHeader() {
    let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    headers = headers.append(
      'USER-LANGUAGE',
      this.language_id ? this.language_id.toString() : '1'
    );
    headers = headers.append(
      'USER-LANGUAGE-CODE',
      this.language_code ? this.language_code.toString() : 'en'
    );
    headers = headers.append('USER-CITY', this.getSelectedCityId()?.toString() || this.city_id.toString());
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append(
      'api-key',
      environment.api_key ? environment.api_key : ''
    );
    this.httpOptions = { headers: headers };
  }

  setEmNewsHeader(city_id) {
    let headers = new HttpHeaders();
      
      // Set the headers
      headers = headers.append(
        'USER-LANGUAGE',
        this.language_id ? this.language_id.toString() : '1'
      );
      headers = headers.append(
        'USER-LANGUAGE-CODE',
        this.language_code ? this.language_code.toString() : 'en'
      );
      headers = headers.append('USER-CITY', city_id.toString());
      headers = headers.append('USER-TOKEN', this.user_token || environment.user_token);
      headers = headers.append(
        'api-key',
        environment.api_key ? environment.api_key : ''
      );
      return { headers: headers };
     
  }
  

  setMiHeader() {
    let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/json');
    headers = headers.append(
      'USER-LANGUAGE',
      this.language_id ? this.language_id.toString() : '1'
    );
    headers = headers.append(
      'USER-LANGUAGE-CODE',
      this.language_code ? this.language_code.toString() : 'en'
    );
    headers = headers.append('USER-CITY', this.city_id.toString());
    // headers = headers.append('USER-CITY', '52');
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append(
      'api-key',
      environment.mi_api_key ? environment.mi_api_key : ''
    );
    this.httpOptions = { headers: headers };
  }

  setEmHeaderTypeMP() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append(
      'USER-LANGUAGE',
      this.language_id ? this.language_id.toString() : '1'
    );
    headers = headers.append('USER-CITY', this.city_id.toString());
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append(
      'api-key',
      environment.api_key ? environment.api_key : ''
    );
    this.httpOptions = { headers: headers };
  }

  getSelectedCityId(){
    let selected_city = this.browserService.getLocalValue('selected_city');
    if(selected_city){
      selected_city = JSON.parse(selected_city);
      return selected_city?.place_id;
    }
    return '';
  }

  // setting header for UMS
  setUmsHeader(content_type = 'application/json') {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', content_type);
    headers = headers.append('app-id', '10');
    headers = headers.append('user-lang', String(this.language_code));
    headers = headers.append('city-id', String(this.city_id));
    headers = headers.append('city-code', String(this.city_code));
    headers = headers.append('user-token', String(this.user_token));
    this.httpOptions = { headers: headers };
  }

  //Setting header for file upload for UMS
  setUploadHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('API-KEY', this.API_KEY);
    headers = headers.append('app-id', '10');
    headers = headers.append('user-lang', 'en');
    headers = headers.append('CITY-ID', String(this.city_id));
    headers = headers.append('city-code', String(this.city_code));
    headers = headers.append('user-token', String(this.user_token));
    this.httpOptions = { headers: headers };
  }

  // post record of em contact form
  postFormData(url: any, file = null) {
    this.setEmHeaderTypeMP();
    return this.http.post(this.emUrl + url, file, this.httpOptions);
  }

  // get record of em
  getData(url: any): Observable<any> {
    this.setEmHeader();
    return this.http.get(this.emUrl + url, this.httpOptions);
  }

  // get data from mi
  getMiData(url: any): Observable<any> {
    this.setMiHeader();
    return this.http.get(this.miUrl + url, this.httpOptions);
  }

  // post record of em
  postData(url: any, body: any) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setEmHeader();
    return this.http.post(this.emUrl + url, body, this.httpOptions);
  }

  // patch record of em
  patchData(url: any, body: any) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setEmHeader();
    return this.http.patch(this.emUrl + url, body, this.httpOptions);
  }

  // delete record of em
  deleteData(url: any) {
    this.setEmHeader();
    return this.http.delete(this.emUrl + url, this.httpOptions);
  }

  //put em data
  putData(url: any, body: any) {
    this.setEmHeader();
    return this.http.put(this.emUrl + url, body, this.httpOptions);
  }

  // get record of em broker data
  getBrokerData(url: any) {
    this.setEmHeader();
    return this.http.get(this.emBrokerUrl + url, this.httpOptions);
  }

  // post record of em broker data
  postBrokerData(url: any, body: any) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setEmHeader();
    return this.http.post(this.emBrokerUrl + url, body, this.httpOptions);
  }

  // patch record of em patch data
  patchBrokerData(url: any, body: any) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setEmHeader();
    return this.http.patch(this.emBrokerUrl + url, body, this.httpOptions);
  }

  // delete record of em broker data
  deleteBrokerData(url: any) {
    this.setEmHeader();
    return this.http.delete(this.emBrokerUrl + url, this.httpOptions);
  }
  //Uploaded File action Ums
  postUmsfile(url: any, body: any) {
    this.setUploadHeader();
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }

  //upload file em
  postEmFile(url: any, body: any) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setUploadHeader();
    return this.http.post(this.emUrl + url, body, this.httpOptions);
  }

  // get record using this function
  getUmsData(url: any) {
    this.setUmsHeader();
    return this.http.get(this.umsUrl + url, this.httpOptions);
  }

  // send a POST request to the API to create a data object
  postUmsData(url: any, body: any) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setUmsHeader();
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }

  // send a PATCH request to the API to update a data object
  patchUmsData(urlWithId: any, body: any) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setUmsHeader();
    return this.http.patch(this.umsUrl + urlWithId, body, this.httpOptions);
  }

  // send a DELETE request to the API to delete a data object
  deleteUmsData(urlWithId: any) {
    this.setUmsHeader();
    return this.http.delete(this.umsUrl + urlWithId, this.httpOptions);
  }

  // get record of v broker data
  getValapiData(url: any) {
    this.setEmHeader();
    return this.http.get(this.emValuationUrl + url, this.httpOptions);
  }

  // post record of em Valapi data
  postValapiData(url: any, body: any) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setEmHeader();
    return this.http.post(this.emValuationUrl + url, body, this.httpOptions);
  }

  // patch record of em patch data
  patchValapiData(url: any, body: any) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setEmHeader();
    return this.http.patch(this.emValuationUrl + url, body, this.httpOptions);
  }

  // getting list of property type
  getLanguageList() {
    this.getUmsData('language').subscribe((res: any) => {
      if (res && res.data) {
        res.data.forEach((element: any) => {
          if (element.status == 1) {
            this.language_list.push(element);
          }
        });
        this.browserService.setLocalValue(
          'language_list',
          JSON.stringify(this.language_list)
        );
        this.setdefaultlang((this.browserService.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar') ? 'ar' : 'en');
      } else {
        if (res.status == 403) {
          this.alertService.warn(res.message);
        }
      }
    });
  }

  setdefaultlang(key: any) {
    let language = this.deviceService.getDeviceInfo();
    this.currentUrlSSR = (language['userAgent']?.includes('-ar/'))?2:1;
    let lng = this.fnc.getArrayValue('language_code', key, this.language_list);
    if (lng) {
      this.browserService.setLocalValue('selected_language', JSON.stringify(lng));
      this.selected_language.next(lng);
      this.browserService.setLocalValue('LOCALIZE_DEFAULT_LANGUAGE', key);
      this.lang.next(key);
    }
  }
/**
 * getPath function to create custom poath
 * @param url 
 * @returns url string
 */
  getPath(city_slug:string, url:string, removeAR:boolean=false) {
    let url_string:string;
    let lang = this.browserService.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE');
    let urlLower = (url?.length) ? url.toLowerCase():'';
    if(city_slug?.length){
      url_string = `${city_slug}-${lang}/${urlLower}`
    } else {
      url_string = `${lang}/${urlLower}`
    }
    return url_string
  }

  getInitialCityData(slug:string=null){
    return new Promise((resolve, reject) => {
    let url =  `initial-load?city_slug=${slug}`;
    if(!slug) return;
      this.getData(url).pipe(take(1)).subscribe({next: (city)=>{
        if(city && city.data && city.data.city){
          this.cityData.next(city.data);
          this.selected_city.next(city.data.city)
          resolve(city.data)
        } else {
          reject(null)
        }
      },
      error:(err)=>{
          this.alertService.warn(err.message);
          reject(null)
      }});
    }).catch(e => {});
  }


  getCitis() {
    return new Promise((resolve, reject) => {
      let cities: any = [];
      if (isPlatformBrowser(this.platformId)) {
        let client_host = this.browserService.location.hostname;
        if(this.city_list?.length){
          resolve(this.city_list);
          return;
        }
        this.getUmsData('country-with-city').pipe(take(1)).subscribe(
          {
            next: (res: any) => {
              if (res.status == 200 && res.data) {
                let path =  window.location.pathname;
                if(path.includes('error')) this.route.navigate(['/']);
                
                res.data.map((city: any) => {
                  var temp = new Array();
                  if(city?.child_data) {
                    city.domain ='';
                     let allowedItem = city.child_data.filter(item => item.status == 1 && item.domain && (item.domain.split(',').indexOf(client_host) > -1))
                     allowedItem.map((list, index) => {city.domain += index?`,${list.domain}` :`${list.domain}`})
                     city.child_data = allowedItem;
                    }
                  var str = city.domain ? city.domain : '';
                  temp = str ? str.split(',') : [];
                  if (city.status == 1 && temp.indexOf(client_host) > -1) {
                    let place: any = {};
                    delete city['database'];
                    delete city['ds_pwd'];
                    delete city['ds_user'];
                    delete city['host'];
                    delete city['parent_place_id'];
                    if (city) {
                      let allowedKeys = [
                        'place_id',
                        'place_code',
                        'place_name',
                        'currency_name',
                        'currency_code',
                        'latitude',
                        'longitude',
                        'image',
                        'domain',
                        'slug',
                        'child_data',
                      ];
                      let arr = Object.keys(city);
                      arr.map((item: any) => {
                        if (allowedKeys.includes(item)) {
                          place[item] = city[item];
                        }
                      });
                    }
                    cities.push(place);
                  }
                });
                if (cities) {
                  this.browserService.setLocalValue(
                    'cities',
                    JSON.stringify(cities)
                  );
                  this.cities.next(cities);
                  this.city_list = cities;
                  // 
                  let lan = this.browserService.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE');
                  let slug = this.route.url.split('-'+lan)[0];
                  let defaultSlug = slug.split('/')[1];
                  let selectedCity = this.city_list.filter((item: any) => item.slug == defaultSlug)[0];
                  if(selectedCity) {
                    // this.getInitialCityData(selectedCity.slug);
                    this.browserService.setLocalValue('selected_city', JSON.stringify(selectedCity))
                    let urlLang = selectedCity.slug+'-'+lan;
                    this.current_city_slug.next(urlLang);
                  }
                  // 
                  resolve(cities);
                }
              } else {
                reject(cities);
              }
            },
            error: (e) => {
              if (e.status >= 500 || e.status  === 0) { 
                // Database / API is down, display an error page to the user
                this.route.navigate(['/error']);
              } else {
                // Handle other errors
                console.error('An error occurred:', e);
              }
               reject(e);
              }
          }
        );
      }
    }).catch(e => {});
  }

  getCitisBySlug(slug) {
    return new Promise((resolve, reject) => {
      let place: any = {};
      if (isPlatformBrowser(this.platformId)) {
        let client_host = this.browserService.location.hostname;
        this.getUmsData('place?slug=' + slug).pipe(take(1)).subscribe({
          next: (res: any) => {
            if (res.status == 200 && res.data) {
              res.data.map((city: any) => {
                var str = city.domain ? city.domain : '';
                var temp = new Array();
                temp = str ? str.split(',') : [];
                if (city.status == 1 && temp.indexOf(client_host) > -1) {
                  delete city['database'];
                  delete city['ds_pwd'];
                  delete city['ds_user'];
                  delete city['host'];
                  delete city['parent_place_id'];
                  if (city) {
                    let allowedKeys = [
                      'place_id',
                      'place_code',
                      'place_name',
                      'currency_name',
                      'currency_code',
                      'latitude',
                      'longitude',
                      'image',
                      'domain',
                      'slug',
                    ];
                    let arr = Object.keys(city);
                    arr.map((item: any) => {
                      if (allowedKeys.includes(item)) {
                        place[item] = city[item];
                      }
                    });
                  }
                  if (place) {
                    this.city_id = place.place_id;
                    this.browserService.setLocalValue(
                      'selected_city',
                      JSON.stringify(place)
                    );
                    this.selected_city.next(place);
                    resolve(place);
                  }
                }
              });
            } else {
              reject(place);
            }
          },
          error: (error) => { }
        });
      }
    });
  }

  // getting allow resources
  getAllowResources(user_id = null) {
    if (user_id) this.user_id = user_id;
    let body = `user/resource?place_id=${this.city_id}&user_id=${this.user_id}`;
    this.getUmsData(body).pipe(take(1)).subscribe({
      next: (res: any) => {
        if (res.status == 403 || res.status == 0) {
          this.logout();
        }
        this.allowResources = res.data;
        this.browserService.setLocalValue(
          'allowResources',
          JSON.stringify(this.allowResources)
        );
      },
      error: (err) => {
        if (err['status'] == 403 || err['status'] == 0) {
          // log out when unauthorised
          this.logout();
        }
      }
    });
  }

  logoutall(){
    this.authService.signOut().then(res => { }).catch(err => { });
    this.getUmsData('logout?all_device=false').pipe(take(1)).subscribe((data) => { });
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('user_info');
      localStorage.removeItem('user_token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('allowResources');
      localStorage.removeItem('broker_list');
      localStorage.removeItem('profile_image');
      // localStorage.removeItem('saved_props');
      this.user_info.next('');
      this.is_user_loggedin.next(false);
      this.profile_image.next('');
      this.user_id = null;
      this.user_token = '';
      this.setApiUrl();
      this.alertService.success(this.translate.instant('Home.user_logout_successfully'));
  }
}

  logout() {
    // console.log("logoutsxbhxb")
      this.broadcastChannel.postMessage({ action: 'logout' });
      // this.route.navigateByUrl(`/em/${this.city_code.toLowerCase()}`)
  }

  // redirectToCity() {
  //   this.route.navigateByUrl(`${this.browserService.getLocalValue('LOCALIZE_DEFAULT_LANGUAGE') == 'ar' ? 'ar' : ''}/em/${this.slug}`)
  // }

  checkRole(role: any) {
    let status = false;
    let roles = this.browserService.getLocalValue('user_role');
    if (roles) {
      let roles_arr = [];
      roles_arr = roles.split(',');
      if (roles_arr.includes(role)) status = true;
    }
    return status;
  }

  getUserRole(userId: any) {
    let url = `users?user_ids=${userId}`;
    this.getUmsData(url).pipe(take(1)).subscribe((data: any) => {
      if (data.data && data.data.length) {
        let val = data.data;
        let roles = val[0].role_names;
        this.browserService.setLocalValue('user_role', roles);
      }
    });
  }

  getUserDetail(id) {
    this.getUmsData(`user?user_id=${id}`).pipe(take(1)).subscribe({
      next: (res: any) => {
        if (res.data) {
          this.setUser_info(res.data);
        }
      },
      error: (err) => { }
    })
  }

  setUser_info(userdata) {
    const user_info = {
      created_date: userdata.created_date,
      date_of_birth: userdata.date_of_birth,
      department_id: userdata.department_id,
      first_name: (userdata.user_details[this.language_id]?.first_name) ? userdata.user_details[this.language_id].first_name : userdata.user_details['1'].first_name,
      gender: userdata.gender,
      is_verified: userdata.is_verified,
      last_name: (userdata.user_details[this.language_id]?.last_name) ? userdata.user_details[this.language_id].last_name : userdata.user_details['1'].last_name,
      lat: userdata.lat,
      lon: userdata.lon,
      nationality: userdata.nationality,
      phone_number: userdata.phone_number,
      primary_email_id: userdata.primary_email_id,
      profile_image: userdata.profile_image,
      token: this.browserService.getLocalValue('user_token'),
      user_id: userdata.user_id
    }
    let user = JSON.parse(this.browserService.getLocalValue('user_info'));

    if (user.broker_list) {
      user_info['broker_list'] = user.broker_list;
    }
    if (user.brokerData) {
      user_info['brokerData'] = user.brokerData;
    }
    this.browserService.setLocalValue('user_info', JSON.stringify(user_info));
    this.browserService.setLocalValue('profile_image', userdata.profile_image);
    this.profile_image.next(userdata.profile_image);
    this.user_info.next(user_info);
  }


  checkResourceAccess(name: any, refreshResource = true) {
    let resource = {
      GET: false,
      PATCH: false,
      POST: false,
      DELETE: false,
    };
    if (refreshResource) {
      this.getAllowResources(); //call the api again
    }
    let resources = this.allowResources;
    resources.forEach((element: any) => {
      if (element.resource_name == name) {
        if (element.methods.indexOf('GET') != -1) {
          resource.GET = true;
        }
        if (element.methods.indexOf('POST') != -1) {
          resource.POST = true;
        }
        if (element.methods.indexOf('PATCH') != -1) {
          resource.PATCH = true;
        }
        if (element.methods.indexOf('DELETE') != -1) {
          resource.DELETE = true;
        }
      }
    });
    return resource;
  }

  changeLanguage(res: any): void {
    if (isPlatformBrowser(this.platformId)) {
      if (
        res !== 'ar' &&
        document.getElementsByTagName('html')[0].hasAttribute('dir')
      ) {
        document.getElementsByTagName('html')[0].removeAttribute('dir');
        document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
      } else if (res === 'ar') {
        document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
        document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');
      }
    }
  }

  ContactData(url, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    // this.setMiHeader();
    return this.http.post(this.miUrl + url, body);
  }

  getNews(url: any, id:any): Observable<any> {
    let header = this.setEmNewsHeader(id);
    return this.http.get(this.emUrl + url, header);
  }
}
